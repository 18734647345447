:root {
  --content-text-scale: 1;
}
body {
  font-size: 14px;
  -webkit-print-color-adjust: exact;
}
#main-content:not([data-dynamic-style="true"]),
.content-excerpt:not([data-dynamic-style="true"]) {
  font-size: calc(1.6rem * var(--content-text-scale));
  line-height: calc(2.3rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) h1,
.content-excerpt:not([data-dynamic-style="true"]) h1,
#main-content:not([data-dynamic-style="true"]) h2,
.content-excerpt:not([data-dynamic-style="true"]) h2,
#main-content:not([data-dynamic-style="true"]) h3,
.content-excerpt:not([data-dynamic-style="true"]) h3,
#main-content:not([data-dynamic-style="true"]) h4,
.content-excerpt:not([data-dynamic-style="true"]) h4,
#main-content:not([data-dynamic-style="true"]) h5,
.content-excerpt:not([data-dynamic-style="true"]) h5,
#main-content:not([data-dynamic-style="true"]) h6,
.content-excerpt:not([data-dynamic-style="true"]) h6 {
  color: #333333;
}
#main-content:not([data-dynamic-style="true"]) p,
.content-excerpt:not([data-dynamic-style="true"]) p {
  margin: 1rem 0 0;
  color: #424242;
}
#main-content:not([data-dynamic-style="true"]) p > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) p > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) p > .title,
.content-excerpt:not([data-dynamic-style="true"]) p > .title {
  display: block;
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) a:not([role="button"]),
.content-excerpt:not([data-dynamic-style="true"]) a:not([role="button"]) {
  text-decoration: underline;
}
#main-content:not([data-dynamic-style="true"]) img,
.content-excerpt:not([data-dynamic-style="true"]) img {
  max-width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
#main-content:not([data-dynamic-style="true"]) pre,
.content-excerpt:not([data-dynamic-style="true"]) pre {
  text-align: left;
}
#main-content:not([data-dynamic-style="true"]) > section:first-child,
.content-excerpt:not([data-dynamic-style="true"]) > section:first-child,
#main-content:not([data-dynamic-style="true"]) > figure:first-child,
.content-excerpt:not([data-dynamic-style="true"]) > figure:first-child,
#main-content:not([data-dynamic-style="true"]) > p:first-child,
.content-excerpt:not([data-dynamic-style="true"]) > p:first-child,
#main-content:not([data-dynamic-style="true"]) > .abstract:first-child,
.content-excerpt:not([data-dynamic-style="true"]) > .abstract:first-child,
#main-content:not([data-dynamic-style="true"]) > table:first-child,
.content-excerpt:not([data-dynamic-style="true"]) > table:first-child {
  margin-top: 0 !important;
}
#main-content:not([data-dynamic-style="true"]) section > section,
.content-excerpt:not([data-dynamic-style="true"]) section > section,
#main-content:not([data-dynamic-style="true"]) section > figure,
.content-excerpt:not([data-dynamic-style="true"]) section > figure {
  margin-top: 3rem;
}
#main-content:not([data-dynamic-style="true"]) > figure,
.content-excerpt:not([data-dynamic-style="true"]) > figure,
#main-content:not([data-dynamic-style="true"]) :not(figure) > figure,
.content-excerpt:not([data-dynamic-style="true"]) :not(figure) > figure {
  counter-increment: figure;
  counter-reset: subfigure;
}
#main-content:not([data-dynamic-style="true"]) figure,
.content-excerpt:not([data-dynamic-style="true"]) figure {
  position: relative;
  color: #424242;
  text-align: center;
  overflow: auto;
}
#main-content:not([data-dynamic-style="true"]) figure > figcaption,
.content-excerpt:not([data-dynamic-style="true"]) figure > figcaption {
  padding: 1rem;
  font-size: calc(1.3rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) figure img,
.content-excerpt:not([data-dynamic-style="true"]) figure img {
  width: 100%;
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) figure > [data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) figure > [data-type="media"],
#main-content:not([data-dynamic-style="true"]) figure > .media,
.content-excerpt:not([data-dynamic-style="true"]) figure > .media {
  display: block;
  margin: 0;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) figure > figure,
.content-excerpt:not([data-dynamic-style="true"]) figure > figure {
  counter-increment: subfigure;
}
#main-content:not([data-dynamic-style="true"]) figure > figure:not(.ui-has-child-figcaption)::after,
.content-excerpt:not([data-dynamic-style="true"]) figure > figure:not(.ui-has-child-figcaption)::after {
  display: block;
  position: relative;
  font-weight: bold;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]),
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) {
  display: table;
  position: relative;
  top: 0;
  table-layout: fixed;
}
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > .title,
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > .title {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figcaption,
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figcaption {
  display: table-caption;
  margin-bottom: 1.5rem;
  caption-side: bottom;
}
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figure,
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figure {
  display: table-cell;
}
#main-content:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figure > figcaption,
.content-excerpt:not([data-dynamic-style="true"]) figure:not([data-orient="vertical"]) > figure > figcaption {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) [data-type="term"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="term"],
#main-content:not([data-dynamic-style="true"]) .term,
.content-excerpt:not([data-dynamic-style="true"]) .term {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-teacher,
.content-excerpt:not([data-dynamic-style="true"]) .os-teacher {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="list"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="list"],
#main-content:not([data-dynamic-style="true"]) .list,
.content-excerpt:not([data-dynamic-style="true"]) .list {
  overflow-wrap: break-word;
}
#main-content:not([data-dynamic-style="true"]) [data-type="list"] > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="list"] > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .list > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .list > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="list"] > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="list"] > .title,
#main-content:not([data-dynamic-style="true"]) .list > .title,
.content-excerpt:not([data-dynamic-style="true"]) .list > .title {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .footnote,
.content-excerpt:not([data-dynamic-style="true"]) .footnote {
  font-size: calc(1rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) [data-type="footnote-ref"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="footnote-ref"] {
  padding-left: 27px;
  text-indent: -27px;
}
#main-content:not([data-dynamic-style="true"]) .abstract,
.content-excerpt:not([data-dynamic-style="true"]) .abstract {
  position: relative;
  margin: 3rem 6rem 0 6rem;
  padding: 4.5rem 1.5rem 1.5rem 1.5rem;
  background-color: #ededed;
}
#main-content:not([data-dynamic-style="true"]) .abstract ul,
.content-excerpt:not([data-dynamic-style="true"]) .abstract ul {
  margin: 1.5rem 0 0 0;
}
#main-content:not([data-dynamic-style="true"]) .abstract ul::after,
.content-excerpt:not([data-dynamic-style="true"]) .abstract ul::after {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  content: "Abstract";
}
#main-content:not([data-dynamic-style="true"]) blockquote,
.content-excerpt:not([data-dynamic-style="true"]) blockquote {
  font-size: calc(16px * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .example [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .example [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) .example [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="exercise-question"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="exercise-question"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .problem,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .problem,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .problem,
#main-content:not([data-dynamic-style="true"]) .example .problem,
.content-excerpt:not([data-dynamic-style="true"]) .example .problem,
#main-content:not([data-dynamic-style="true"]) .exercise .problem,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .problem,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .problem,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .problem,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution,
#main-content:not([data-dynamic-style="true"]) .example .solution,
.content-excerpt:not([data-dynamic-style="true"]) .example .solution,
#main-content:not([data-dynamic-style="true"]) .exercise .solution,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .solution,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .solution,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .exercise-question,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .exercise-question,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .exercise-question,
#main-content:not([data-dynamic-style="true"]) .example .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) .example .exercise-question,
#main-content:not([data-dynamic-style="true"]) .exercise .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .exercise-question,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .exercise-question,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .exercise-question {
  padding: 0.5em 1em;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .example [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution,
#main-content:not([data-dynamic-style="true"]) .example .solution,
.content-excerpt:not([data-dynamic-style="true"]) .example .solution,
#main-content:not([data-dynamic-style="true"]) .exercise .solution,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .solution,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .solution,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .solution {
  border-top: 0.1rem solid #424242;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .example .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .example .solution > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle {
  font-weight: bold;
  text-align: center;
  list-style: none;
  cursor: pointer;
  text-transform: capitalize;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .example .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .example .solution > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle::-webkit-details-marker,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle::-webkit-details-marker,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle::-webkit-details-marker {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .example [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise [data-type="solution"] > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] .solution > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .example .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .example .solution > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise .solution > .ui-toggle::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise .solution > .ui-toggle::before {
  content: "[" attr(data-content) "]";
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example.check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise.check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.check-understanding [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.check-understanding [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="check-understanding"] .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="check-understanding"] .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="check-understanding"] .title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="check-understanding"] .title::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="check-understanding"] .title::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="check-understanding"] .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="check-understanding"] .title::before {
  margin-right: 0;
  content: "";
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .example.conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .example.conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .exercise.conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise.conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise.conceptual-questions [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.conceptual-questions [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="conceptual-questions"] .problem,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="conceptual-questions"] .problem,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="conceptual-questions"] .problem,
#main-content:not([data-dynamic-style="true"]) .example[data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="conceptual-questions"] .problem,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="conceptual-questions"] .problem,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="conceptual-questions"] .problem,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="conceptual-questions"] .problem {
  border-top: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) .example.conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) .example.conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) .exercise.conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.conceptual-questions [data-type="problem"] p,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.conceptual-questions [data-type="problem"] p,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="conceptual-questions"] .problem p,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="conceptual-questions"] .problem p,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="conceptual-questions"] .problem p,
#main-content:not([data-dynamic-style="true"]) .example[data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="conceptual-questions"] .problem p,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="conceptual-questions"] .problem p,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="conceptual-questions"] .problem p,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="conceptual-questions"] .problem p {
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .solution::before {
  color: #424242;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="problem"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="problem"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .problem::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .problem::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .problem::before {
  content: "Problem";
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="solution"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] [data-type="solution"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"].problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .example.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .example.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .exercise.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise.problems-exercises .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"][data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .exercise[data-type="problems-exercises"] .solution::before,
#main-content:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .solution::before,
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise[data-type="problems-exercises"] .solution::before {
  content: "Solution";
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] div[data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] div[data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="injected-exercise"] div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="injected-exercise"] div[data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example div[data-type="title"],
#main-content:not([data-dynamic-style="true"]) .exercise div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .exercise div[data-type="title"],
#main-content:not([data-dynamic-style="true"]) .injected-exercise div[data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .injected-exercise div[data-type="title"] {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .table-wrapper,
.content-excerpt:not([data-dynamic-style="true"]) .table-wrapper {
  overflow-x: auto;
}
#main-content:not([data-dynamic-style="true"]) table,
.content-excerpt:not([data-dynamic-style="true"]) table {
  width: 100%;
  max-width: 100%;
  margin-top: 6rem;
  margin-bottom: 20px;
  background-color: transparent;
  counter-increment: table;
}
#main-content:not([data-dynamic-style="true"]) table caption,
.content-excerpt:not([data-dynamic-style="true"]) table caption {
  margin-top: 0.5rem;
  font-size: calc(1.3rem * var(--content-text-scale));
  text-align: left;
  caption-side: bottom;
}
#main-content:not([data-dynamic-style="true"]) table caption sup,
.content-excerpt:not([data-dynamic-style="true"]) table caption sup {
  top: auto;
  line-height: inherit;
}
#main-content:not([data-dynamic-style="true"]) table caption > .title,
.content-excerpt:not([data-dynamic-style="true"]) table caption > .title {
  display: block;
  font-size: calc(1.8rem * var(--content-text-scale));
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) table caption > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) table caption > .title::before {
  margin-right: 0.5rem;
  color: #606163;
  font-weight: bold;
  content: "Table " counter(table) ".";
}
#main-content:not([data-dynamic-style="true"]) table thead tr > th,
.content-excerpt:not([data-dynamic-style="true"]) table thead tr > th {
  padding: 0.5em 1em;
}
#main-content:not([data-dynamic-style="true"]) table tbody tr > td[data-align="right"],
.content-excerpt:not([data-dynamic-style="true"]) table tbody tr > td[data-align="right"] {
  text-align: right;
}
#main-content:not([data-dynamic-style="true"]) table tbody tr > td,
.content-excerpt:not([data-dynamic-style="true"]) table tbody tr > td {
  padding: 0.5em 1em;
  border: 0.1rem solid #ddd;
}
#main-content:not([data-dynamic-style="true"]) table tfoot > tr > th,
.content-excerpt:not([data-dynamic-style="true"]) table tfoot > tr > th,
#main-content:not([data-dynamic-style="true"]) table tfoot > tr > td,
.content-excerpt:not([data-dynamic-style="true"]) table tfoot > tr > td {
  padding: 0.5em 1em;
  border: 0.1rem solid #ddd;
  line-height: 1.42857143;
  vertical-align: top;
}
#main-content:not([data-dynamic-style="true"]) table thead > tr > th,
.content-excerpt:not([data-dynamic-style="true"]) table thead > tr > th,
#main-content:not([data-dynamic-style="true"]) table thead > tr > td,
.content-excerpt:not([data-dynamic-style="true"]) table thead > tr > td {
  border: 0.2rem solid #ddd;
  font-weight: bold;
  text-align: left;
  vertical-align: bottom;
}
#main-content:not([data-dynamic-style="true"]) table tbody + tbody,
.content-excerpt:not([data-dynamic-style="true"]) table tbody + tbody {
  border: 0.2rem solid #ddd;
}
#main-content:not([data-dynamic-style="true"]) table table,
.content-excerpt:not([data-dynamic-style="true"]) table table {
  background-color: #fff;
}
#main-content:not([data-dynamic-style="true"]) [data-type="glossary"] [data-type="definition"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="glossary"] [data-type="definition"] {
  margin: 1rem 3rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="equation"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="equation"] {
  overflow: auto;
}
#main-content:not([data-dynamic-style="true"]) [data-type="code"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="code"] {
  font-family: 'IBM Plex Mono', monospace;
}
#main-content:not([data-dynamic-style="true"]) [data-type="code"].blue-code,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="code"].blue-code,
#main-content:not([data-dynamic-style="true"]) [data-type="code"] .blue-code,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="code"] .blue-code {
  color: #2E3092;
}
#main-content:not([data-dynamic-style="true"]) [data-type="code"].red-code,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="code"].red-code,
#main-content:not([data-dynamic-style="true"]) [data-type="code"] .red-code,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="code"] .red-code {
  color: #B90000;
}
#main-content:not([data-dynamic-style="true"]) pre[data-type="code"],
.content-excerpt:not([data-dynamic-style="true"]) pre[data-type="code"] {
  display: block;
  overflow: auto;
}
#main-content:not([data-dynamic-style="true"]) pre[data-type="code"].centered-code,
.content-excerpt:not([data-dynamic-style="true"]) pre[data-type="code"].centered-code {
  color: #424242;
  text-align: center;
  background: none;
  margin: 0 auto;
}
#main-content:not([data-dynamic-style="true"]) .primary-function,
.content-excerpt:not([data-dynamic-style="true"]) .primary-function,
#main-content:not([data-dynamic-style="true"]) .secondary-function,
.content-excerpt:not([data-dynamic-style="true"]) .secondary-function {
  font-family: 'IBM Plex Mono', monospace;
}
#main-content:not([data-dynamic-style="true"]) .primary-function,
.content-excerpt:not([data-dynamic-style="true"]) .primary-function {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) h1,
.content-excerpt:not([data-dynamic-style="true"]) h1,
#main-content:not([data-dynamic-style="true"]) h2,
.content-excerpt:not([data-dynamic-style="true"]) h2,
#main-content:not([data-dynamic-style="true"]) h3,
.content-excerpt:not([data-dynamic-style="true"]) h3,
#main-content:not([data-dynamic-style="true"]) h4,
.content-excerpt:not([data-dynamic-style="true"]) h4,
#main-content:not([data-dynamic-style="true"]) h5,
.content-excerpt:not([data-dynamic-style="true"]) h5,
#main-content:not([data-dynamic-style="true"]) h6,
.content-excerpt:not([data-dynamic-style="true"]) h6 {
  margin: 1.5rem 0 1rem 0;
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) h3 + section,
.content-excerpt:not([data-dynamic-style="true"]) h3 + section {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) ul,
.content-excerpt:not([data-dynamic-style="true"]) ul,
#main-content:not([data-dynamic-style="true"]) ol,
.content-excerpt:not([data-dynamic-style="true"]) ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #424242;
}
#main-content:not([data-dynamic-style="true"]) .stepwise,
.content-excerpt:not([data-dynamic-style="true"]) .stepwise {
  list-style-type: none;
  padding-left: 0;
}
#main-content:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-token,
.content-excerpt:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-token {
  white-space: pre;
}
#main-content:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-content > ul,
.content-excerpt:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-content > ul,
#main-content:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-content ol,
.content-excerpt:not([data-dynamic-style="true"]) .stepwise > li .os-stepwise-content ol {
  padding-left: 1rem;
}
#main-content:not([data-dynamic-style="true"]) .os-stepwise,
.content-excerpt:not([data-dynamic-style="true"]) .os-stepwise {
  list-style-type: none;
  padding-left: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-token,
.content-excerpt:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-token {
  white-space: pre;
}
#main-content:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-content > ul,
.content-excerpt:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-content > ul,
#main-content:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-content ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-stepwise > li .os-stepwise-content ol {
  padding-left: 1rem;
}
#main-content:not([data-dynamic-style="true"]) .os-abstract,
.content-excerpt:not([data-dynamic-style="true"]) .os-abstract {
  list-style-type: none;
  padding-left: 2rem;
}
#main-content:not([data-dynamic-style="true"]) .circled,
.content-excerpt:not([data-dynamic-style="true"]) .circled {
  list-style-type: none;
  padding-left: 1rem;
}
#main-content:not([data-dynamic-style="true"]) [data-bullet-style="none"],
.content-excerpt:not([data-dynamic-style="true"]) [data-bullet-style="none"] {
  list-style-type: none;
}
#main-content:not([data-dynamic-style="true"]) img,
.content-excerpt:not([data-dynamic-style="true"]) img {
  height: auto;
}
#main-content:not([data-dynamic-style="true"]) iframe,
.content-excerpt:not([data-dynamic-style="true"]) iframe {
  display: block;
  margin: 3rem auto;
}
#main-content:not([data-dynamic-style="true"]) .centered-text,
.content-excerpt:not([data-dynamic-style="true"]) .centered-text {
  display: block;
  font-weight: normal;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) .os-chapter-outline ul,
.content-excerpt:not([data-dynamic-style="true"]) .os-chapter-outline ul {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) .colored-text,
.content-excerpt:not([data-dynamic-style="true"]) .colored-text {
  color: #CC0000;
}
#main-content:not([data-dynamic-style="true"]) .no-emphasis,
.content-excerpt:not([data-dynamic-style="true"]) .no-emphasis {
  font-weight: normal;
}
#main-content:not([data-dynamic-style="true"]) > section,
.content-excerpt:not([data-dynamic-style="true"]) > section,
#main-content:not([data-dynamic-style="true"]) > figure,
.content-excerpt:not([data-dynamic-style="true"]) > figure,
#main-content:not([data-dynamic-style="true"]) > [data-type="glossary"],
.content-excerpt:not([data-dynamic-style="true"]) > [data-type="glossary"],
#main-content:not([data-dynamic-style="true"]) > [data-type="footnote-refs"],
.content-excerpt:not([data-dynamic-style="true"]) > [data-type="footnote-refs"] {
  margin-top: 6rem;
}
#main-content:not([data-dynamic-style="true"]) > section > ol > li::before,
.content-excerpt:not([data-dynamic-style="true"]) > section > ol > li::before,
#main-content:not([data-dynamic-style="true"]) > figure > ol > li::before,
.content-excerpt:not([data-dynamic-style="true"]) > figure > ol > li::before,
#main-content:not([data-dynamic-style="true"]) > [data-type="glossary"] > ol > li::before,
.content-excerpt:not([data-dynamic-style="true"]) > [data-type="glossary"] > ol > li::before,
#main-content:not([data-dynamic-style="true"]) > [data-type="footnote-refs"] > ol > li::before,
.content-excerpt:not([data-dynamic-style="true"]) > [data-type="footnote-refs"] > ol > li::before {
  content: "" !important;
}
#main-content:not([data-dynamic-style="true"]) .splash,
.content-excerpt:not([data-dynamic-style="true"]) .splash {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) .splash:not([data-orient="vertical"]),
.content-excerpt:not([data-dynamic-style="true"]) .splash:not([data-orient="vertical"]) {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .splash:not([data-orient="vertical"]) img,
.content-excerpt:not([data-dynamic-style="true"]) .splash:not([data-orient="vertical"]) img {
  width: 100%;
}
#main-content:not([data-dynamic-style="true"]) figure.splash.unnumbered,
.content-excerpt:not([data-dynamic-style="true"]) figure.splash.unnumbered {
  display: table;
  margin: 3rem auto;
}
#main-content:not([data-dynamic-style="true"]) figure.splash.unnumbered figcaption,
.content-excerpt:not([data-dynamic-style="true"]) figure.splash.unnumbered figcaption {
  text-align: left;
  padding-left: 0rem;
}
#main-content:not([data-dynamic-style="true"]) span[data-type="media"] img,
.content-excerpt:not([data-dynamic-style="true"]) span[data-type="media"] img {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .os-figure,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure {
  display: table;
  margin: 3rem auto;
}
#main-content:not([data-dynamic-style="true"]) .os-figure .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure .os-caption-container {
  display: table-caption;
  padding-top: 1rem;
  color: #424242;
  font-size: calc(1.2rem * var(--content-text-scale));
  caption-side: bottom;
}
#main-content:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-title-label,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-title-label {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-number {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure .os-caption-container .os-title {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) h1.example-title .text,
.content-excerpt:not([data-dynamic-style="true"]) h1.example-title .text {
  padding-left: 1rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"],
#main-content:not([data-dynamic-style="true"]) .note,
.content-excerpt:not([data-dynamic-style="true"]) .note {
  margin: 3rem 6rem 0 6rem;
  margin: 3rem 0;
  border: 0.2rem solid gainsboro;
  background-color: #ededed;
}
@media (max-width: 767px) {
  #main-content:not([data-dynamic-style="true"]) [data-type="note"],
  .content-excerpt:not([data-dynamic-style="true"]) [data-type="note"],
  #main-content:not([data-dynamic-style="true"]) .note,
  .content-excerpt:not([data-dynamic-style="true"]) .note {
    margin-right: 0;
    margin-left: 0;
  }
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] > p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] > p,
#main-content:not([data-dynamic-style="true"]) .note > p,
.content-excerpt:not([data-dynamic-style="true"]) .note > p {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  font-size: calc(1.5rem * var(--content-text-scale));
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  font-size: calc(1.5rem * var(--content-text-scale));
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  font-size: calc(1.5rem * var(--content-text-scale));
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .note[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] > section,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] > section,
#main-content:not([data-dynamic-style="true"]) .note > section,
.content-excerpt:not([data-dynamic-style="true"]) .note > section {
  padding: 0.5rem 1.5rem;
  border-top: 0.1rem solid #424242;
  padding: 0rem 3rem 1.5rem;
  border-top: none;
  background-color: #ededed;
  color: #424242;
  overflow-x: auto;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] > section > *:not(section):not(header):first-child,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] > section > *:not(section):not(header):first-child,
#main-content:not([data-dynamic-style="true"]) .note > section > *:not(section):not(header):first-child,
.content-excerpt:not([data-dynamic-style="true"]) .note > section > *:not(section):not(header):first-child {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] > section > header,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] > section > header,
#main-content:not([data-dynamic-style="true"]) .note > section > header,
.content-excerpt:not([data-dynamic-style="true"]) .note > section > header {
  margin-top: 3rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] > section span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] > section span[data-type="media"],
#main-content:not([data-dynamic-style="true"]) .note > section span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) .note > section span[data-type="media"] {
  display: block;
  margin: 1rem 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"],
#main-content:not([data-dynamic-style="true"]) .example,
.content-excerpt:not([data-dynamic-style="true"]) .example {
  margin: 3rem 0;
  border: 0.2rem solid gainsboro;
  background-color: #ededed;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] > p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] > p,
#main-content:not([data-dynamic-style="true"]) .example > p,
.content-excerpt:not([data-dynamic-style="true"]) .example > p {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"]:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example:not([data-label]).ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label=''].ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .os-title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > .title,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > .title,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"],
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"] {
  display: inline-block;
  display: block;
  margin: 0 1.5rem 0;
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 0.2rem solid gainsboro;
  color: #424242;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > [data-type="title"]::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > header > .os-title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > .title::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title > .title::before,
#main-content:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"][data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
#main-content:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before,
.content-excerpt:not([data-dynamic-style="true"]) .example[data-label]:not([data-label='']).ui-has-child-title .solution > section > [data-type="solution-title"]::before {
  display: none;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] > section,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] > section,
#main-content:not([data-dynamic-style="true"]) .example > section,
.content-excerpt:not([data-dynamic-style="true"]) .example > section {
  padding: 0.5rem 1.5rem;
  border-top: 0.1rem solid #424242;
  padding: 0rem 3rem 1.5rem;
  border-top: none;
  background-color: #ededed;
  color: #424242;
  overflow-x: auto;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] > section > *:not(section):not(header):first-child,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] > section > *:not(section):not(header):first-child,
#main-content:not([data-dynamic-style="true"]) .example > section > *:not(section):not(header):first-child,
.content-excerpt:not([data-dynamic-style="true"]) .example > section > *:not(section):not(header):first-child {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] > section > header,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] > section > header,
#main-content:not([data-dynamic-style="true"]) .example > section > header,
.content-excerpt:not([data-dynamic-style="true"]) .example > section > header {
  margin-top: 3rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] > section span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] > section span[data-type="media"],
#main-content:not([data-dynamic-style="true"]) .example > section span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) .example > section span[data-type="media"] {
  display: block;
  margin: 1rem 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="problem"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="problem"],
#main-content:not([data-dynamic-style="true"]) [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) .problem,
.content-excerpt:not([data-dynamic-style="true"]) .problem,
#main-content:not([data-dynamic-style="true"]) .solution,
.content-excerpt:not([data-dynamic-style="true"]) .solution {
  padding: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container {
  display: inline;
}
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > :first-child:not(ul):not(ol):not([data-type="note"]):not(.os-figure),
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > :first-child:not(ul):not(ol):not([data-type="note"]):not(.os-figure),
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > :first-child:not(ul):not(ol):not([data-type="note"]):not(.os-figure),
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > :first-child:not(ul):not(ol):not([data-type="note"]):not(.os-figure) {
  display: inline;
}
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > ul,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > ul,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > ul,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > ul,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container > ol,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container > ol,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container [data-type="note"],
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container [data-type="note"],
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container [data-type="note"],
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container [data-type="note"] {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container .os-figure,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container .os-figure,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container .os-figure,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container .os-figure {
  margin: 3rem 0;
}
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container img,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-problem-container img,
#main-content:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container img,
.content-excerpt:not([data-dynamic-style="true"]) .os-number ~ .os-solution-container img {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .review-questions [type="a"],
.content-excerpt:not([data-dynamic-style="true"]) .review-questions [type="a"],
#main-content:not([data-dynamic-style="true"]) .os-review-questions-container [type="a"],
.content-excerpt:not([data-dynamic-style="true"]) .os-review-questions-container [type="a"] {
  list-style-type: upper-alpha;
}
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="problem"] p:first-of-type,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="problem"] p:first-of-type {
  display: inline-block;
}
#main-content:not([data-dynamic-style="true"]) [data-type="note"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] [data-type="solution"],
#main-content:not([data-dynamic-style="true"]) [data-type="note"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="note"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="exercise"] .solution,
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .solution,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .solution {
  border-top: 0.1rem solid gainsboro;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .os-solution-container,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .os-solution-container {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) [data-type="example"] .os-solution-container p,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="example"] .os-solution-container p {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) .equation,
.content-excerpt:not([data-dynamic-style="true"]) .equation,
#main-content:not([data-dynamic-style="true"]) [data-type="equation"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="equation"] {
  display: table;
  width: 100%;
}
#main-content:not([data-dynamic-style="true"]) .equation .os-equation-number,
.content-excerpt:not([data-dynamic-style="true"]) .equation .os-equation-number,
#main-content:not([data-dynamic-style="true"]) [data-type="equation"] .os-equation-number,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="equation"] .os-equation-number {
  display: table-cell;
  width: 5%;
  vertical-align: middle;
}
#main-content:not([data-dynamic-style="true"]) .equation .os-equation-number .os-number,
.content-excerpt:not([data-dynamic-style="true"]) .equation .os-equation-number .os-number,
#main-content:not([data-dynamic-style="true"]) [data-type="equation"] .os-equation-number .os-number,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="equation"] .os-equation-number .os-number {
  padding: 5px;
  border: black solid 1px;
  text-align: center;
  vertical-align: middle;
}
#main-content:not([data-dynamic-style="true"]) .equation [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .equation [data-type="title"],
#main-content:not([data-dynamic-style="true"]) [data-type="equation"] [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="equation"] [data-type="title"] {
  display: block;
  font-weight: bold;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) .os-note-body img,
.content-excerpt:not([data-dynamic-style="true"]) .os-note-body img {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .os-note-body [data-type="exercise"]:first-of-type,
.content-excerpt:not([data-dynamic-style="true"]) .os-note-body [data-type="exercise"]:first-of-type {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) .os-table table thead tr th .MathJax_Display,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table thead tr th .MathJax_Display {
  width: auto !important;
  margin: 0px;
  float: left !important;
}
#main-content:not([data-dynamic-style="true"]) .swipe-table,
.content-excerpt:not([data-dynamic-style="true"]) .swipe-table {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Chrome / Opera */
  cursor: -webkit-grab;
}
#main-content:not([data-dynamic-style="true"]) .os-table,
.content-excerpt:not([data-dynamic-style="true"]) .os-table {
  overflow-x: auto;
  margin: 20px 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table .os-table-title,
.content-excerpt:not([data-dynamic-style="true"]) .os-table .os-table-title {
  padding-bottom: 1em;
  font-weight: bold;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) .os-table table,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table {
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table table.has-images,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table.has-images {
  table-layout: fixed;
}
#main-content:not([data-dynamic-style="true"]) .os-table table tr td,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table tr td,
#main-content:not([data-dynamic-style="true"]) .os-table table tr th,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table tr th {
  padding: 0.5em 1em;
}
#main-content:not([data-dynamic-style="true"]) .os-table table tr td,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table tr td {
  vertical-align: middle;
}
#main-content:not([data-dynamic-style="true"]) .os-table table tr [data-valign="top"],
.content-excerpt:not([data-dynamic-style="true"]) .os-table table tr [data-valign="top"] {
  vertical-align: top;
}
#main-content:not([data-dynamic-style="true"]) .os-table table tr [data-align="center"],
.content-excerpt:not([data-dynamic-style="true"]) .os-table table tr [data-align="center"] {
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) .os-table table ul,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ul,
#main-content:not([data-dynamic-style="true"]) .os-table table ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ol {
  padding-left: 1.5em;
}
#main-content:not([data-dynamic-style="true"]) .os-table table ul[data-bullet-style="none"],
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ul[data-bullet-style="none"],
#main-content:not([data-dynamic-style="true"]) .os-table table ol[data-bullet-style="none"],
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ol[data-bullet-style="none"] {
  margin: 0;
  padding: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table table ul[data-bullet-style="none"] li,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ul[data-bullet-style="none"] li,
#main-content:not([data-dynamic-style="true"]) .os-table table ol[data-bullet-style="none"] li,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table ol[data-bullet-style="none"] li {
  list-style: none;
}
#main-content:not([data-dynamic-style="true"]) .os-table table .os-figure,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table .os-figure {
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table table .os-figure img,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table .os-figure img {
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table table .os-figure .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-table table .os-figure .os-caption-container {
  padding: 1rem 0 0 0;
}
#main-content:not([data-dynamic-style="true"]) .os-table .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-table .os-caption-container {
  padding: 8px;
  border-top: 0.1rem solid gainsboro;
  font-size: 0.9em;
}
#main-content:not([data-dynamic-style="true"]) .os-table .os-caption-container .os-title-label,
.content-excerpt:not([data-dynamic-style="true"]) .os-table .os-caption-container .os-title-label,
#main-content:not([data-dynamic-style="true"]) .os-table .os-caption-container .os-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-table .os-caption-container .os-number {
  display: inline-block;
  padding-right: 0.25em;
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc h2[data-type="document-title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc h2[data-type="document-title"],
#main-content:not([data-dynamic-style="true"]) .os-eob h2[data-type="document-title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eob h2[data-type="document-title"],
#main-content:not([data-dynamic-style="true"]) .os-eoc h2.os-title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc h2.os-title,
#main-content:not([data-dynamic-style="true"]) .os-eob h2.os-title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob h2.os-title {
  font-size: calc(2.1rem * var(--content-text-scale));
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc .os-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .os-number,
#main-content:not([data-dynamic-style="true"]) .os-eob .os-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .os-number {
  font-weight: bold;
  text-decoration: none;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc .group-by .os-index-item:not(:first-of-type),
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .group-by .os-index-item:not(:first-of-type),
#main-content:not([data-dynamic-style="true"]) .os-eob .group-by .os-index-item:not(:first-of-type),
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .group-by .os-index-item:not(:first-of-type) {
  margin-top: 0.5rem;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc .group-by .os-index-item .os-term,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .group-by .os-index-item .os-term,
#main-content:not([data-dynamic-style="true"]) .os-eob .group-by .os-index-item .os-term,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .group-by .os-index-item .os-term {
  padding-right: 0.5rem;
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc .group-label,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .group-label,
#main-content:not([data-dynamic-style="true"]) .os-eob .group-label,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .group-label {
  display: block;
  margin: 1.5rem 0 1rem 0;
  font-size: calc(2.1rem * var(--content-text-scale));
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc.os-reference-container > .os-chapter-area > .reference span.os-reference-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc.os-reference-container > .os-chapter-area > .reference span.os-reference-number,
#main-content:not([data-dynamic-style="true"]) .os-eob.os-reference-container > .os-chapter-area > .reference span.os-reference-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob.os-reference-container > .os-chapter-area > .reference span.os-reference-number,
#main-content:not([data-dynamic-style="true"]) .os-eoc.os-references-container .references .os-note-body > a,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc.os-references-container .references .os-note-body > a,
#main-content:not([data-dynamic-style="true"]) .os-eob.os-references-container .references .os-note-body > a,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob.os-references-container .references .os-note-body > a {
  margin-right: 10px;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="list"] > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="list"] > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .os-eob [data-type="list"] > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eob [data-type="list"] > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .os-eoc .list > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .list > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .os-eob .list > [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .list > [data-type="title"],
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="list"] > .title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="list"] > .title,
#main-content:not([data-dynamic-style="true"]) .os-eob [data-type="list"] > .title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob [data-type="list"] > .title,
#main-content:not([data-dynamic-style="true"]) .os-eoc .list > .title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .list > .title,
#main-content:not([data-dynamic-style="true"]) .os-eob .list > .title,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .list > .title {
  margin-top: 15px;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] ol,
#main-content:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] ol,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] ol {
  display: inline-grid;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] span[data-type="media"],
#main-content:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] span[data-type="media"],
.content-excerpt:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] span[data-type="media"] {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] table,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="solution"] table,
#main-content:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] table,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob [data-type="solution"] table {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) .os-reference-number,
.content-excerpt:not([data-dynamic-style="true"]) .os-reference-number {
  font-weight: bold;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="exercise"] [data-type="problem"] > .number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="exercise"] [data-type="problem"] > .number,
#main-content:not([data-dynamic-style="true"]) .os-eoc .exercise [data-type="problem"] > .number,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .exercise [data-type="problem"] > .number {
  font-weight: bold;
  text-decoration: none;
}
#main-content:not([data-dynamic-style="true"]) .os-eoc [data-type="exercise"] img,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc [data-type="exercise"] img,
#main-content:not([data-dynamic-style="true"]) .os-eoc .exercise img,
.content-excerpt:not([data-dynamic-style="true"]) .os-eoc .exercise img {
  display: block;
  margin-bottom: 1em;
}
#main-content:not([data-dynamic-style="true"]) .os-eob .os-solution-container img,
.content-excerpt:not([data-dynamic-style="true"]) .os-eob .os-solution-container img {
  display: block;
}
#main-content:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"],
.content-excerpt:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] {
  padding: 0.5em 0.25em 0.5em 0;
}
#main-content:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] > a,
.content-excerpt:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] > a {
  font-weight: bold;
  text-decoration: none;
}
#main-content:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] p,
.content-excerpt:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] p {
  display: inline;
}
#main-content:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] p::before,
.content-excerpt:not([data-dynamic-style="true"]) .os-solutions-container [data-type="solution"] p::before {
  content: " ";
}
#main-content:not([data-dynamic-style="true"]) .os-chapter-area [data-type="solution"] p,
.content-excerpt:not([data-dynamic-style="true"]) .os-chapter-area [data-type="solution"] p {
  display: inline;
}
#main-content:not([data-dynamic-style="true"]) .preface ul,
.content-excerpt:not([data-dynamic-style="true"]) .preface ul {
  list-style-type: none;
}
#main-content:not([data-dynamic-style="true"]) .appendix [data-type="list"],
.content-excerpt:not([data-dynamic-style="true"]) .appendix [data-type="list"] {
  margin-top: 1rem;
}
#main-content:not([data-dynamic-style="true"]) .appendix [data-type="exercise"] .os-problem-container p,
.content-excerpt:not([data-dynamic-style="true"]) .appendix [data-type="exercise"] .os-problem-container p {
  margin-top: 0;
}
#main-content:not([data-dynamic-style="true"]) figure.scaled-down,
.content-excerpt:not([data-dynamic-style="true"]) figure.scaled-down {
  width: 60%;
  margin: auto;
}
#main-content:not([data-dynamic-style="true"]) figure.scaled-down ~ .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) figure.scaled-down ~ .os-caption-container {
  width: 60%;
  margin: auto;
}
#main-content:not([data-dynamic-style="true"]) figure.scaled-down-30,
.content-excerpt:not([data-dynamic-style="true"]) figure.scaled-down-30 {
  width: 30%;
  margin: auto;
}
#main-content:not([data-dynamic-style="true"]) figure.scaled-down-30 ~ .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) figure.scaled-down-30 ~ .os-caption-container {
  width: 30%;
  margin: auto;
}
#main-content:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down,
.content-excerpt:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down {
  display: block;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down img,
.content-excerpt:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down img {
  width: 60%;
}
#main-content:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down-30,
.content-excerpt:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down-30 {
  display: block;
  text-align: center;
}
#main-content:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down-30 img,
.content-excerpt:not([data-dynamic-style="true"]) :not(figure) > [data-type="media"].scaled-down-30 img {
  width: 30%;
}
#main-content:not([data-dynamic-style="true"]) .learning-objectives,
.content-excerpt:not([data-dynamic-style="true"]) .learning-objectives {
  margin: 3rem 0;
  padding: 1.5rem;
  border: 0.2rem solid #dcdcdc;
  background-color: #ededed;
}
#main-content:not([data-dynamic-style="true"]) .learning-objectives [data-type="title"],
.content-excerpt:not([data-dynamic-style="true"]) .learning-objectives [data-type="title"] {
  display: block;
  margin-top: 0;
  padding: 0 1.5rem 1rem;
  border-bottom: 0.2rem solid #dcdcdc;
  color: #555;
  font-size: calc(1.7rem * var(--content-text-scale));
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) .learning-objectives p,
.content-excerpt:not([data-dynamic-style="true"]) .learning-objectives p {
  margin: 1rem 0 1rem 1.5rem;
  color: #555;
}
#main-content:not([data-dynamic-style="true"]) .learning-objectives[data-type="note"],
.content-excerpt:not([data-dynamic-style="true"]) .learning-objectives[data-type="note"] {
  padding: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="media"][data-display="inline"],
.content-excerpt:not([data-dynamic-style="true"]) [data-type="media"][data-display="inline"] {
  display: inline-flex;
}
#main-content:not([data-dynamic-style="true"]) .not-converted-yet,
.content-excerpt:not([data-dynamic-style="true"]) .not-converted-yet {
  visibility: hidden;
}
@media screen {
  #main-content:not([data-dynamic-style="true"]) [data-media~=screenreader],
  .content-excerpt:not([data-dynamic-style="true"]) [data-media~=screenreader] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  #main-content:not([data-dynamic-style="true"]) [data-media]:not([data-media~=screen],[data-media~=screenreader]),
  .content-excerpt:not([data-dynamic-style="true"]) [data-media]:not([data-media~=screen],[data-media~=screenreader]) {
    display: none;
  }
}
@media print {
  #main-content:not([data-dynamic-style="true"]) [data-media]:not([data-media~=print]),
  .content-excerpt:not([data-dynamic-style="true"]) [data-media]:not([data-media~=print]) {
    display: none;
  }
}
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../static/fonts/ibm-plex-sans-v9-latin-italic.woff2') format('woff2'), url('../../static/fonts/ibm-plex-sans-v9-latin-italic.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../static/fonts/ibm-plex-sans-v9-latin-regular.woff2') format('woff2'), url('../../static/fonts/ibm-plex-sans-v9-latin-regular.woff') format('woff');
}
#main-content:not([data-dynamic-style="true"]) a,
.content-excerpt:not([data-dynamic-style="true"]) a {
  color: #027EB5;
  cursor: pointer;
  text-decoration: underline;
}
#main-content:not([data-dynamic-style="true"]) a:hover,
.content-excerpt:not([data-dynamic-style="true"]) a:hover {
  color: #0064A0;
}
#main-content:not([data-dynamic-style="true"]) p.public-domain,
.content-excerpt:not([data-dynamic-style="true"]) p.public-domain,
#main-content:not([data-dynamic-style="true"]) p.student-sample,
.content-excerpt:not([data-dynamic-style="true"]) p.student-sample {
  font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
  padding: 1rem 2rem;
  margin: 1rem 0 2rem;
}
#main-content:not([data-dynamic-style="true"]) p.public-domain,
.content-excerpt:not([data-dynamic-style="true"]) p.public-domain {
  border-left: 0.2rem solid #156851;
}
#main-content:not([data-dynamic-style="true"]) p.student-sample,
.content-excerpt:not([data-dynamic-style="true"]) p.student-sample {
  border-left: 0.2rem solid #005F9A;
}
#main-content:not([data-dynamic-style="true"]) p.annotation-text,
.content-excerpt:not([data-dynamic-style="true"]) p.annotation-text {
  color: #156851;
  font-style: italic;
  font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
}
#main-content:not([data-dynamic-style="true"]) span.os-icons,
.content-excerpt:not([data-dynamic-style="true"]) span.os-icons {
  display: flex;
  float: left;
}
#main-content:not([data-dynamic-style="true"]) span.linguistic-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.linguistic-icon,
#main-content:not([data-dynamic-style="true"]) span.culture-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.culture-icon,
#main-content:not([data-dynamic-style="true"]) span.dreaming-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.dreaming-icon,
#main-content:not([data-dynamic-style="true"]) span.visual-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.visual-icon,
#main-content:not([data-dynamic-style="true"]) span.speech-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.speech-icon,
#main-content:not([data-dynamic-style="true"]) span.auditory-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.auditory-icon,
#main-content:not([data-dynamic-style="true"]) span.kinesthetic-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.kinesthetic-icon {
  width: 2rem;
  height: 2rem;
  margin: 0.15rem 1rem 0.15rem 0;
}
#main-content:not([data-dynamic-style="true"]) span.linguistic-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.linguistic-icon {
  background: url('../../static/icons/cosmos-linguistic.svg');
}
#main-content:not([data-dynamic-style="true"]) span.culture-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.culture-icon {
  background: url('../../static/icons/cosmos-culture.svg');
}
#main-content:not([data-dynamic-style="true"]) span.dreaming-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.dreaming-icon {
  background: url('../../static/icons/cosmos-dreaming.svg');
}
#main-content:not([data-dynamic-style="true"]) span.visual-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.visual-icon {
  background: url('../../static/icons/cosmos-visual.svg');
}
#main-content:not([data-dynamic-style="true"]) span.speech-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.speech-icon {
  background: url('../../static/icons/cosmos-speech.svg');
}
#main-content:not([data-dynamic-style="true"]) span.auditory-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.auditory-icon {
  background: url('../../static/icons/cosmos-auditory.svg');
}
#main-content:not([data-dynamic-style="true"]) span.kinesthetic-icon,
.content-excerpt:not([data-dynamic-style="true"]) span.kinesthetic-icon {
  background: url('../../static/icons/cosmos-kinesthetic.svg');
}
#main-content:not([data-dynamic-style="true"]) u[data-effect="double-underline"],
.content-excerpt:not([data-dynamic-style="true"]) u[data-effect="double-underline"] {
  text-decoration-style: double;
}
#main-content:not([data-dynamic-style="true"]) span[data-screenreader-only="true"],
.content-excerpt:not([data-dynamic-style="true"]) span[data-screenreader-only="true"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
#main-content:not([data-dynamic-style="true"]) sup,
.content-excerpt:not([data-dynamic-style="true"]) sup {
  top: -0.5em;
}
#main-content:not([data-dynamic-style="true"]) sub,
.content-excerpt:not([data-dynamic-style="true"]) sub {
  bottom: -0.25em;
}
#main-content:not([data-dynamic-style="true"]) h2,
.content-excerpt:not([data-dynamic-style="true"]) h2 {
  font-size: calc(3.2rem * var(--content-text-scale));
  line-height: calc(3.5rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) h3,
.content-excerpt:not([data-dynamic-style="true"]) h3 {
  font-size: calc(2.4rem * var(--content-text-scale));
  line-height: calc(2.3rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) h4,
.content-excerpt:not([data-dynamic-style="true"]) h4 {
  font-size: calc(1.8rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) sub,
.content-excerpt:not([data-dynamic-style="true"]) sub,
#main-content:not([data-dynamic-style="true"]) sup,
.content-excerpt:not([data-dynamic-style="true"]) sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
#main-content:not([data-dynamic-style="true"]) .os-figure .os-caption-container,
.content-excerpt:not([data-dynamic-style="true"]) .os-figure .os-caption-container {
  line-height: calc(1.7rem * var(--content-text-scale));
}
#main-content:not([data-dynamic-style="true"]) .os-glossary-container dt,
.content-excerpt:not([data-dynamic-style="true"]) .os-glossary-container dt {
  font-weight: 700;
}
#main-content:not([data-dynamic-style="true"]) .os-glossary-container dd,
.content-excerpt:not([data-dynamic-style="true"]) .os-glossary-container dd {
  margin-left: 0;
  margin-inline-start: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="composite-page"] [data-type="exercise"] [data-type="problem"] p:first-of-type a,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="composite-page"] [data-type="exercise"] [data-type="problem"] p:first-of-type a {
  font-weight: 700;
  margin-right: 0.5em;
}
#main-content:not([data-dynamic-style="true"]) code,
.content-excerpt:not([data-dynamic-style="true"]) code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
#main-content:not([data-dynamic-style="true"]) blockquote,
.content-excerpt:not([data-dynamic-style="true"]) blockquote {
  padding: 1rem 2rem;
  margin: 1rem 0 2rem;
  border-left: 0.5rem solid #dcdcdc;
}
#main-content:not([data-dynamic-style="true"]) figure,
.content-excerpt:not([data-dynamic-style="true"]) figure {
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) table,
.content-excerpt:not([data-dynamic-style="true"]) table {
  border-collapse: collapse;
  border-spacing: 0;
}
#main-content:not([data-dynamic-style="true"]) .btn-link,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link,
#main-content:not([data-dynamic-style="true"]) .btn-link:hover,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link:hover,
#main-content:not([data-dynamic-style="true"]) .btn-link:focus,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link:focus,
#main-content:not([data-dynamic-style="true"]) .btn-link:active,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link:active {
  border-color: transparent;
}
#main-content:not([data-dynamic-style="true"]) .btn-link,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link,
#main-content:not([data-dynamic-style="true"]) .btn-link:active,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link:active,
#main-content:not([data-dynamic-style="true"]) .btn-link.active,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link.active,
#main-content:not([data-dynamic-style="true"]) .btn-link[disabled],
.content-excerpt:not([data-dynamic-style="true"]) .btn-link[disabled],
#main-content:not([data-dynamic-style="true"]) fieldset[disabled] .btn-link,
.content-excerpt:not([data-dynamic-style="true"]) fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#main-content:not([data-dynamic-style="true"]) .btn-link,
.content-excerpt:not([data-dynamic-style="true"]) .btn-link {
  color: #21366b;
  font-weight: 400;
  border-radius: 0;
}
#main-content:not([data-dynamic-style="true"]) input,
.content-excerpt:not([data-dynamic-style="true"]) input,
#main-content:not([data-dynamic-style="true"]) button,
.content-excerpt:not([data-dynamic-style="true"]) button,
#main-content:not([data-dynamic-style="true"]) select,
.content-excerpt:not([data-dynamic-style="true"]) select,
#main-content:not([data-dynamic-style="true"]) textarea,
.content-excerpt:not([data-dynamic-style="true"]) textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
#main-content:not([data-dynamic-style="true"]) button,
.content-excerpt:not([data-dynamic-style="true"]) button,
#main-content:not([data-dynamic-style="true"]) html input[type="button"],
.content-excerpt:not([data-dynamic-style="true"]) html input[type="button"],
#main-content:not([data-dynamic-style="true"]) input[type="reset"],
.content-excerpt:not([data-dynamic-style="true"]) input[type="reset"],
#main-content:not([data-dynamic-style="true"]) input[type="submit"],
.content-excerpt:not([data-dynamic-style="true"]) input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
#main-content:not([data-dynamic-style="true"]) button,
.content-excerpt:not([data-dynamic-style="true"]) button,
#main-content:not([data-dynamic-style="true"]) select,
.content-excerpt:not([data-dynamic-style="true"]) select {
  text-transform: none;
}
#main-content:not([data-dynamic-style="true"]) button,
.content-excerpt:not([data-dynamic-style="true"]) button {
  overflow: visible;
}
#main-content:not([data-dynamic-style="true"]) button,
.content-excerpt:not([data-dynamic-style="true"]) button,
#main-content:not([data-dynamic-style="true"]) input,
.content-excerpt:not([data-dynamic-style="true"]) input,
#main-content:not([data-dynamic-style="true"]) optgroup,
.content-excerpt:not([data-dynamic-style="true"]) optgroup,
#main-content:not([data-dynamic-style="true"]) select,
.content-excerpt:not([data-dynamic-style="true"]) select,
#main-content:not([data-dynamic-style="true"]) textarea,
.content-excerpt:not([data-dynamic-style="true"]) textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
#main-content:not([data-dynamic-style="true"]) [data-type="footnote-ref-content"]:before,
.content-excerpt:not([data-dynamic-style="true"]) [data-type="footnote-ref-content"]:before {
  /* In previous version there was a whitespace in the content */
  content: " ";
}
#main-content:not([data-dynamic-style="true"]) .coreq-skills,
.content-excerpt:not([data-dynamic-style="true"]) .coreq-skills {
  padding: 0 3rem 3rem 3rem;
  border: 1px solid #dcdcdc;
}
#main-content:not([data-dynamic-style="true"]) .coreq-skills > .os-title,
.content-excerpt:not([data-dynamic-style="true"]) .coreq-skills > .os-title {
  margin: 0 -3rem;
  padding: 1.5rem 3rem;
  font-size: 1.7rem;
  color: #424242;
  background-color: #ececec;
  border-bottom: 1px solid #dcdcdc;
  text-transform: uppercase;
}
#main-content:not([data-dynamic-style="true"]) .coreq-skills .os-coreq-exercises .os-problem-container,
.content-excerpt:not([data-dynamic-style="true"]) .coreq-skills .os-coreq-exercises .os-problem-container,
#main-content:not([data-dynamic-style="true"]) .coreq-skills .os-coreq-exercises .os-problem-container > p:first-child,
.content-excerpt:not([data-dynamic-style="true"]) .coreq-skills .os-coreq-exercises .os-problem-container > p:first-child {
  display: inline;
}
#main-content:not([data-dynamic-style="true"]) .os-table td,
.content-excerpt:not([data-dynamic-style="true"]) .os-table td {
  height: 3rem;
}
.content-excerpt:not([data-dynamic-style="true"]) > section {
  margin-top: 0;
}
.content-excerpt:not([data-dynamic-style="true"]) dt {
  font-weight: 700;
}
/*# sourceMappingURL=content.css.map */